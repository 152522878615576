/* eslint-disable */
import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import EmployeesOffice from '../images/people/employees-office-black-and-white.jpg'
import EmployeesOfficeMobile from '../images/people/employees-office-black-and-white.jpg'
import ClearValues from '../images/illustrations/clear-values-black.png'

import useWindowSize from '@leshen/ui/src/hooks/useWindowSize'

import {
  Accordion,
  Billboard,
  SplitContent,
  VariableContent,
  Typography,
  LinkButton,
  Banner,
  Link,
  Stack,
} from '@leshen/gatsby-theme-leshen'
import { Layout } from '@leshen/gatsby-theme-contentful'

import header from '../components/Header'
import footer from '../components/Footer'
import Footer from '../components/Footer'
import footerData from '../data/footerData'
import headerData from '../data/headerData'

const Home = () => {
  const windowSize = useWindowSize()
  const isMobile = windowSize.width < 940
  const layoutProps = {
    data: {
      contentfulPage: {
        header: header(),
        footer: footer(),
        seo: {
          title: 'Our Culture | Clearlink',
          description:
            'Meet our company founder, learn about our commitment to inclusion and diversity, and discover the values that guide our decision-making on a daily basis.',
          canonical: 'https://www.clearlink.com/',
          robots: 'follow,index',
        },
        path: '/who-we-are',
        hideStickyCTA: false,
        spanish: false,
        sections: [
          {
            // Add any relevant section data
          },
        ],
      },
      site: {
        siteMetadata: {
          siteURL: 'https://www.clearlink.com',
          siteName: 'Clearlink',
          alternateName: 'Clearlink',
          removeTrailingSlashes: true,
        },
      },
    },
    titleTemplate: 'Our Culture | Clearlink',
    defaultRobots: 'follow,index',
    header: header(),
    footer: footer(),
    main: (
      <>
        <Billboard
          backgroundColor="dark"
          alignImageToBottom
          mobileAlignImageToBottom
          className="content-transparent-desktop mobile-image-full"
          variant="full"
          image={
            <img
              src={EmployeesOffice}
              alt="employees working in an office environment"
            />
          }
          mobileImage={
            <img
              src={EmployeesOfficeMobile}
              alt="employees working in an office environment"
            />
          }
          mainContent={
            <div
              data-sal="slide-right"
              data-sal-delay="100"
              data-sal-duration="500"
              data-sal-easing="ease"
            >
              <Typography variant="h1">
                We're committed to helping people
                <span className="blue-punctuation-large">.</span>
              </Typography>
              <Typography variant="h4" style={{ marginBottom: '40px' }}>
                We believe everyone should have access to the best tools and
                resources when selecting every day goods and services.
              </Typography>
              <LinkButton
                style={{ marginBottom: '32px' }}
                to={`/careers`}
                variant="hero"
                color="primary"
              >
                Join Our Team
              </LinkButton>
            </div>
          }
        />
        <VariableContent
          backgroundColor="white"
          alignMainContent="center"
          mainContent={
            <>
              <div
                data-sal="slide-up"
                data-sal-delay="100"
                data-sal-duration="500"
                data-sal-easing="ease"
              >
                <Typography variant="h2">
                  Clearlink Values
                  <span className="blue-punctuation-large">.</span>
                </Typography>
              </div>
              {isMobile ? (
                <div
                  data-sal="slide-up"
                  data-sal-delay="300"
                  data-sal-duration="500"
                  data-sal-easing="ease"
                >
                  <img
                    src={ClearValues}
                    alt=""
                    placeholder="blurred"
                    layout="fullWidth"
                  />
                </div>
              ) : (
                <div
                  data-sal="slide-up"
                  data-sal-delay="300"
                  data-sal-duration="500"
                  data-sal-easing="ease"
                >
                  <img
                    src={ClearValues}
                    alt=""
                    placeholder="blurred"
                    layout="fullWidth"
                  />
                </div>
              )}
            </>
          }
        >
          <div
            data-sal="slide-up"
            data-sal-delay="100"
            data-sal-duration="500"
            data-sal-easing="ease"
            style={{ marginBottom: '40px' }}
          >
            <Typography variant="h4" className="narrow center">
              Create Community
            </Typography>
            <Typography className="narrow center">
              You see positive intent in your coworkers. You recognize that
              everyone has something to teach, and allow space for others to
              share their skills and knowledge. You validate those around you.
            </Typography>
            <Typography className="narrow center">
              You show genuine interest in those you work with. You discuss
              business and life outside of work. You are authentic, transparent,
              and empathetic. You celebrate others’ wins enthusiastically.
            </Typography>
            <Typography className="narrow center">
              We are passionate about our people.
            </Typography>
          </div>
          <div
            data-sal="slide-up"
            data-sal-delay="100"
            data-sal-duration="500"
            data-sal-easing="ease"
            style={{ marginBottom: '40px' }}
          >
            <Typography variant="h4" className="narrow center">
              Learn and Grow
            </Typography>
            <Typography className="narrow center">
              You push to be a better version of yourself, every single day.
            </Typography>
            <Typography className="narrow center">
              When you’re frustrated by challenges, you focus on asking better
              questions to uncover better solutions. You strive to have a growth
              mindset. You learn from mistakes, you seek feedback rather than
              validation, and you tackle struggles head-on.
            </Typography>
            <Typography className="narrow center">
              We have each other’s backs, and we welcome challenges as
              opportunities to learn, grow, and succeed together.
            </Typography>
          </div>
          <div
            data-sal="slide-up"
            data-sal-delay="100"
            data-sal-duration="500"
            data-sal-easing="ease"
            style={{ marginBottom: '40px' }}
          >
            <Typography variant="h4" className="narrow center">
              Embrace Opportunity
            </Typography>
            <Typography className="narrow center">
              You live up to your full potential. You let change work for and
              benefit you, so you can be happier with your work and the process
              of growth.
            </Typography>
            <Typography className="narrow center">
              You actively seek out the endless possibilities available to you.
              You embrace change with a calm and relaxed mind. You know where
              you are going and what you are set out to accomplish.
            </Typography>
            <Typography className="narrow center">
              We stay focused and set our sights high.
            </Typography>
          </div>
          <div
            data-sal="slide-up"
            data-sal-delay="100"
            data-sal-duration="500"
            data-sal-easing="ease"
            style={{ marginBottom: '40px' }}
          >
            <Typography variant="h4" className="narrow center">
              Act Like an Owner
            </Typography>
            <Typography className="narrow center">
              You default to action. You proactively share solutions and follow
              through on commitments. You have high expectations of yourself and
              your coworkers. You recognize and celebrate your colleagues’ wins.
              You have the humility to own your failures so you can improve.
              You’re capable of leading or following as the situation demands.
            </Typography>
            <Typography className="narrow center">
              We all have the ability to make a measurable impact and own our
              path to success.
            </Typography>
          </div>
          <div
            data-sal="slide-up"
            data-sal-delay="100"
            data-sal-duration="500"
            data-sal-easing="ease"
          >
            <Typography variant="h4" className="narrow center">
              Respect Every Person
            </Typography>
            <Typography className="narrow center">
              You demonstrate empathy for your coworkers and users alike. You
              show you care about our business by keeping commitments because
              you know your work and relationships impact our bottom line.
            </Typography>
            <Typography className="narrow center">
              We are passionate about being transparent and keeping our
              coworkers’, users’, and customers’ best interests in mind.
            </Typography>
          </div>
        </VariableContent>
        <VariableContent
          backgroundColor="dark"
          alignMainContent="center"
          id="forbes"
          mainContent={
            <>
              <div
                data-sal="slide-up"
                data-sal-delay="100"
                data-sal-duration="500"
                data-sal-easing="ease"
              >
                <Typography variant="h2">
                  From Our Founder and CEO:{' '}
                  <span className="clean-wrap">
                    "We're Better Together
                    <span className="blue-punctuation-large">.</span>"
                  </span>
                </Typography>
                <Typography>
                  Our Founder and CEO sat down with Forbes to share his love for
                  Clearlink and why he believes{' '}
                  <span className="clean-wrap">
                    we are just better together
                  </span>
                </Typography>
              </div>
            </>
          }
        >
          <div
            data-sal="slide-up"
            data-sal-delay="100"
            data-sal-duration="500"
            data-sal-easing="ease"
          >
            <video
              width="100%"
              height="auto"
              controls
              poster="https://res.cloudinary.com/clearlink/image/upload/v1712091939/Clearlink/Our%20Culture/james-interview-still-play_zn5q9m.jpg"
            >
              <source
                src="https://res.cloudinary.com/clearlink/video/upload/v1710192336/Clearlink/james-clarke-forbes-interview-edit_mtjmtc.mp4"
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
          </div>
        </VariableContent>
        <SplitContent
          backgroundColor="light"
          image={
            <StaticImage
              src="../images/people/clearlink-team-rounded-corners-black-and-white.png"
              alt="Clearlinkers at working at their desks"
              placeholder="blurred"
            />
          }
          mobileImage={
            <StaticImage
              src="../images/people/clearlink-team-rounded-corners-black-and-white-mobile.png"
              alt="Clearlinkers at working at their desks"
              placeholder="blurred"
            />
          }
          mainContent={
            <div
              data-sal="slide-right"
              data-sal-delay="100"
              data-sal-duration="500"
              data-sal-easing="ease"
            >
              <Typography variant="h2">
                Our commitment to Diversity, Equity, and Inclusion
                <span className="blue-punctuation-large">.</span>
              </Typography>
              <Typography>
                At Clearlink, everyone is a part; no one is apart. Ours is a
                community for everyone. We embrace diversity in experience,
                identity, and perspective to help each of us learn, grow, and
                expand our capabilities.
              </Typography>
              <Typography>
                Clearlink proudly follows the Parity Pledge to help us move
                toward gender and racial parity. We interview and consider at
                least one qualified woman and person of color for every open
                role, director-level and above. We ensure our job descriptions
                are free of unconscious bias and gendered language. We want
                job-seekers to know they belong here.
              </Typography>
              <Typography>
                We’ve also established long-term partnerships with groups that
                prioritize DEI, including the Utah Black Chamber, Equality Utah,
                and the Women Tech Council.
              </Typography>
              <Typography>
                We also know the work is not done. Every year, we review our
                policies and benefits against the Human Rights Campaign
                Foundation’s Corporate Equality Index, the national benchmarking
                tool on corporate policies, practices and benefits pertinent to
                our LGBTQ+ community.
              </Typography>
            </div>
          }
        />
        <Banner
          backgroundColor="secondary"
          className="reverse-desktop"
          alignImageToBottom={false}
          imageAlignment="Center"
          layout="50/50"
          contentAlignment="Center"
          image={
            <img
              src="https://res.cloudinary.com/clearlink/image/upload/v1721148552/Clearlink/Our%20Culture/100_Businesses_Seal_White_NoBG_qkqkly.png"
              alt=""
            />
          }
          mobileImage={false}
          mainContent={
            <Typography>
              We’re honored to be named as one of the 100 companies championing
              women in Utah. This designation is based on “family-friendly
              policies and practices, as well as women-specific initiatives
              known to impact the recruiting, hiring, retaining, and advancing
              women employees, managers, and leaders.”{' '}
              <Link
                className="underline nowrap"
                to="https://inutah.org/100-companies/"
              >
                {' '}
                Visit the Inspire In Utah website
              </Link>
              .
            </Typography>
          }
        />
        <div
          data-sal="slide-up"
          data-sal-delay="100"
          data-sal-duration="500"
          data-sal-easing="ease"
        >
          <VariableContent backgroundColor="dark">
            <Typography variant="h2">
              ERGs at Clearlink<span className="blue-punctuation-large">.</span>
            </Typography>
            <Typography style={{ marginBottom: '48px' }}>
              At Clearlink we are committed to investing into our community in
              various ways, including through our Employee Resource Groups
              (ERGs).
            </Typography>
            <Stack spacing="xxl">
              <Accordion
                items={[
                  {
                    title: 'Queerlinkers',
                    content:
                      'Promote and maintain a safe and inclusive community for LGBTQIA+ employees at Clearlink.',
                  },
                  {
                    title: 'People of Color – People of Clearlink',
                    content:
                      'Aims to help the Clearlink BIPOC (Black, Indigenous, and People of Color) community acknowledge their experiences and create space for healthy identity-based conversations.',
                  },
                  {
                    title: 'Women at Clearlink',
                    content:
                      'The Women of Clearlink ERG is a safe space for women to be seen and supported. Together, Women of Clearlink unpack gender biases, advocate for each other, and embrace unique identities.',
                  },
                  {
                    title: 'Neurodiversity Group',
                    content:
                      'This ERG advocates for and provides resources to the neurodivergent community at Clearlink. Regardless of each person’s differences, employees deserve the opportunity to reach their full potential both professionally and personally.',
                  },
                ]}
              />
            </Stack>
          </VariableContent>
        </div>
      </>
    ),
    disableBreadcrumbs: true,
  }

  return <Layout {...layoutProps} />
}
export default Home
